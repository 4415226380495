import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation, Link } from "react-router-dom";
import DashboardVendorMain from "../vendor";
import { Icon } from "@iconify/react";

const styles = {
  sidebar: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "250px", // Adjust width as needed
    height: "95vh",
    backgroundColor: "#fff",
    overflowY: "auto", // Add scrollbar when content overflows
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add shadow for visual effect
    zIndex: 1000, // Ensure sidebar appears above other content
    padding: "15px", // Add padding to content
  },
  navItemActive: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
  },
  navItem: {
    marginBottom: "10px",
    cursor: "pointer",
  },
  navLink: {
    textDecoration: "none",
    color: "#333",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
};


function DashboardSidebarMain() {
  const location = useLocation();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [mainOrdersOpen, setMainOrdersOpen] = useState(false);
  const [orderSub, setOrdersSub] = useState(false);
  useEffect(() => {
    if (location.pathname.startsWith('/orders')) {
      setOrdersSub(true);
    } else {
      setOrdersSub(false);
    }
  }, [location.pathname]);
  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const toggleMainOrders = () => {
    setMainOrdersOpen(!mainOrdersOpen);
  };


  return (
    <>
      <div className="categories-dropdown-wrap style-2 font-heading mt-30" style={styles.sidebar}>
        <div className="d-flex categori-dropdown-inner">
          <ul>
            <a href="/dash-vendor">
              <li className={isActiveRoute("/dash") ? "nav-item-active" : "nav-item"}>
                <Icon icon="mdi:domain" className="me-2" color="green" />
                Dashboard
              </li>
            </a>
            <a href="/dash-vendor">
              <li className={isActiveRoute("/dash-vendor") ? "nav-item-active" : "nav-item"}>
                <Icon icon="mdi:domain" className="me-2" color="green" />
                Vendor
              </li></a>
            <a href="/admin-users">
              <li className={isActiveRoute("/admin-users") ? "nav-item-active" : "nav-item"}>
                <Icon icon="subway:admin-1" className="me-2" color="green" />
                Admin Users
              </li></a>
            <a href="/users">
              <li className={isActiveRoute("/users") ? "nav-item-active" : "nav-item"}>
                <Icon icon="solar:user-id-bold" className="me-2" color="green" />
                Users
              </li></a>
            <a href="/dash-product"> <li className={isActiveRoute("/dash-product") ? "nav-item-active" : "nav-item"}>
              <Icon icon="solar:box-bold" color="green" className="me-2" />
              Product
            </li></a>
            <a href="/dash-category">
              <li className={isActiveRoute("/dash-category") ? "nav-item-active" : "nav-item"}>
                <Icon icon="mdi:category" className="me-2" color="green" />
                Category
              </li></a>

            <a href="/dash-sub-category">  <li className={isActiveRoute("/dash-sub-category") ? "nav-item-active" : "nav-item"}>
              <Icon icon="carbon:category" className="me-2" color="green" />
              Sub Category
            </li> </a>
            <a href="/product-group">
              <li className={isActiveRoute("/product-group") ? "nav-item-active" : "nav-item"}>
                <Icon icon="mdi:category" className="me-2" color="green" />
                Product Group
              </li></a>
            <div onClick={() => setOrdersSub(!orderSub)} >  <li className={isActiveRoute("/orders") ? "nav-item-active" : "nav-item"}>
              <Icon icon="carbon:ibm-data-product-exchange" className="me-2" color="green" />
              Orders
            </li> </div>
            {orderSub &&
              <>
                <Link to="/orders">  <li className={isActiveRoute("/orders") ? "nav-item-active" : "nav-item"}>
                  <Icon icon="carbon:delivery-parcel" className="me-2" color="green" />
                  All Orders
                </li> </Link>
                <Link to="/orders-active">  <li className={isActiveRoute("/orders-active") ? "nav-item-active" : "nav-item"}>
                  <Icon icon="mdi:package-variant-closed-delivered" className="me-2" color="green" />
                  Active Orders
                </li> </Link>
                <Link to="/orders-pending">  <li className={isActiveRoute("/orders-pending") ? "nav-item-active" : "nav-item"}>
                  <Icon icon="ri:pass-pending-fill" className="me-2" color="green" />
                  Pending Orders
                </li> </Link>
                <Link to="/orders-cancelled">  <li className={isActiveRoute("/orders-cancelled") ? "nav-item-active" : "nav-item"}>
                  <Icon icon="material-symbols:cancel-presentation-rounded" className="me-2" color="green" />
                  Cancelled Orders
                </li> </Link>
                <Link to="/orders-history">  <li className={isActiveRoute("/orders-history") ? "nav-item-active" : "nav-item"}>
                  <Icon icon="material-symbols:deployed-code-history-rounded" className="me-2" color="green" />
                  Order History
                </li> </Link>
              </>
            }
             <a href="/mail">
              <li className={isActiveRoute("/mail") ? "nav-item-active" : "nav-item"}>
                <Icon icon="fluent:mail-add-24-filled" className="me-2" color="green" />
                Bulk Mail
              </li></a>
            <a href="/deals">  <li className={isActiveRoute("/deals") ? "nav-item-active" : "nav-item"}>
              <Icon icon="fluent:calendar-day-24-filled" className="me-2" color="green" />
              Deals of the day
            </li> </a>
            <a href="/popular">  <li className={isActiveRoute("/popular") ? "nav-item-active" : "nav-item"}>
              <Icon icon="icon-park-outline:trend-two" className="me-2" color="green" />
              Popular Products
            </li> </a>
            <a href="/top_selling">  <li className={isActiveRoute("/top_selling") ? "nav-item-active" : "nav-item"}>
              <Icon icon="carbon:sales-ops" className="me-2" color="green" />
              Top Selling
            </li> </a>
            <a href="/trending">  <li className={isActiveRoute("/trending") ? "nav-item-active" : "nav-item"}>
              <Icon icon="fluent:data-trending-24-filled" className="me-2" color="green" />
              Trending
            </li> </a>
            <a href="/recently">  <li className={isActiveRoute("/recently") ? "nav-item-active" : "nav-item"}>
              <Icon icon="system-uicons:box-add" className="me-2" color="green" />
              Recently Added
            </li> </a>
            <a href="/top-rated">  <li className={isActiveRoute("/top-rated") ? "nav-item-active" : "nav-item"}>
              <Icon icon="icon-park-solid:align-top-two" className="me-2" color="green" />
              Top Rated
            </li> </a>
            <a href="/tab1">  <li className={isActiveRoute("/tab1") ? "nav-item-active" : "nav-item"}>
              <Icon icon="simple-icons:rubygems" className="me-2" color="green" />
              Kerala Gems
            </li> </a>
            <a href="/tab2">  <li className={isActiveRoute("/tab2") ? "nav-item-active" : "nav-item"}>
              <Icon icon="mdi:file-find" className="me-2" color="green" />
              Featured Finds
            </li> </a>
            <a href="/tab3">  <li className={isActiveRoute("/tab3") ? "nav-item-active" : "nav-item"}>
              <Icon icon="icon-park-solid:traditional-chinese-medicine" className="me-2" color="green" />
              Ayurvedic Delight
            </li> </a>
            <a href="/banner">  <li className={isActiveRoute("/banner") ? "nav-item-active" : "nav-item"}>
              <Icon icon="carbon:sales-ops" className="me-2" color="green" />
              Banner
            </li> </a>
            <a href="/reviews">  <li className={isActiveRoute("/reviews") ? "nav-item-active" : "nav-item"}>
              <Icon icon="carbon:review" className="me-2" color="green" />
              Reviews
            </li> </a>
            <a href="/coupons">  <li className={isActiveRoute("/coupons") ? "nav-item-active" : "nav-item"}>
              <Icon icon="mdi:coupon" className="me-2" color="green" />
              Coupons
            </li> </a>
            <a href="/blogs">  <li className={isActiveRoute("/blogs") ? "nav-item-active" : "nav-item"}>
              <Icon icon="fa6-brands:blogger" className="me-2" color="green" />
              Blogs
            </li> </a>

            <a href="/users-contactus">  <li className={isActiveRoute("/users-contactus") ? "nav-item-active" : "nav-item"}>
              <Icon icon="mage:message-check-round-fill" className="me-2" color="green" />
              Contact info
            </li> </a>

          </ul>
        </div>
      </div>


      {/* <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100 bg-black">
        <a
          href="/"
          class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <span class="fs-5 d-none d-sm-inline">Menu</span>
        </a>
        <ul
          class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
        >
          <li class="nav-item">
            <a href="#" class="nav-link align-middle px-0">
              <i class="fs-4 bi-house"></i>{" "}
              <span class="ms-1 d-none d-sm-inline">Home</span>
            </a>
          </li>
          <li>
            <a
              href="#submenu1"
              data-bs-toggle="collapse"
              class="nav-link px-0 align-middle"
            >
              <i class="fs-4 bi-speedometer2"></i>{" "}
              <span class="ms-1 d-none d-sm-inline">Dashboard</span>{" "}
            </a>
            <ul
              class="collapse show nav flex-column ms-1"
              id="submenu1"
              data-bs-parent="#menu"
            >
              <li class="w-100">
                <a href="#" class="nav-link px-0">
                  {" "}
                  <span class="d-none d-sm-inline">Item</span> 1{" "}
                </a>
              </li>
              <li>
                <a href="#" class="nav-link px-0">
                  {" "}
                  <span class="d-none d-sm-inline">Item</span> 2{" "}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-table"></i>{" "}
              <span class="ms-1 d-none d-sm-inline">Orders</span>
            </a>
          </li>
          <li>
            <a
              href="#submenu2"
              data-bs-toggle="collapse"
              class="nav-link px-0 align-middle "
            >
              <i class="fs-4 bi-bootstrap"></i>{" "}
              <span class="ms-1 d-none d-sm-inline">Bootstrap</span>
            </a>
            <ul
              class="collapse nav flex-column ms-1"
              id="submenu2"
              data-bs-parent="#menu"
            >
              <li class="w-100">
                <a href="#" class="nav-link px-0">
                  {" "}
                  <span class="d-none d-sm-inline">Item</span> 1
                </a>
              </li>
              <li>
                <a href="#" class="nav-link px-0">
                  {" "}
                  <span class="d-none d-sm-inline">Item</span> 2
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#submenu3"
              data-bs-toggle="collapse"
              class="nav-link px-0 align-middle"
            >
              <i class="fs-4 bi-grid"></i>{" "}
              <span class="ms-1 d-none d-sm-inline">Products</span>{" "}
            </a>
            <ul
              class="collapse nav flex-column ms-1"
              id="submenu3"
              data-bs-parent="#menu"
            >
              <li class="w-100">
                <a href="#" class="nav-link px-0">
                  {" "}
                  <span class="d-none d-sm-inline">Product</span> 1
                </a>
              </li>
              <li>
                <a href="#" class="nav-link px-0">
                  {" "}
                  <span class="d-none d-sm-inline">Product</span> 2
                </a>
              </li>
              <li>
                <a href="#" class="nav-link px-0">
                  {" "}
                  <span class="d-none d-sm-inline">Product</span> 3
                </a>
              </li>
              <li>
                <a href="#" class="nav-link px-0">
                  {" "}
                  <span class="d-none d-sm-inline">Product</span> 4
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="nav-link px-0 align-middle">
              <i class="fs-4 bi-people"></i>{" "}
              <span class="ms-1 d-none d-sm-inline">Customers</span>{" "}
            </a>
          </li>
        </ul>
        <hr></hr>
        <div class="dropdown pb-4">
          <a
            href="#"
            class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://github.com/mdo.png"
              alt="hugenerd"
              width="30"
              height="30"
              class="rounded-circle"
            />
            <span class="d-none d-sm-inline mx-1">loser</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
            <li>
              <a class="dropdown-item" href="#">
                New project...
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                Settings
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                Profile
              </a>
            </li>
            <li>
              <hr class="dropdown-divider"></hr>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </div> */}
    </>
  );
}
export default DashboardSidebarMain;
