import React, { useEffect, useState } from "react";
import { backend, blogurl, producturl } from "../../../../path";
import LoaderMain from "../../../layout/header/loader_sub";

function BlogUser() {

  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getblog", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="contact">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-item d-inline-block" title="Home"> Home </a>
            <span></span>
            <div className="breadcrumb-item d-inline-block active"> Blog </div>
          </div>
        </div>
      </div>
      {loading &&
        <LoaderMain />
      }
      <section className="banners py-5">
        <div className="container">
          <div className="col-lg-12 col-md-12">
            <a className="btn btn-lg mb-2 w-100"
              style={{
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset', fontSize: '18px',
                backdropFilter: 'inherit',
                backgroundBlendMode: 'color-burn',
                backgroundColor: '#ADD899',
                fontWeight: 'bolder',
                color: '#fff',
              }}
            >
              KeralaSpecial Blogs
            </a>
          </div>
          <div className="row justify-content-center my-3">
            {data?.map((blog) => (
              <div key={blog.blog_id} className="col-lg-4 col-md-6">
                <div
                  className="banner-img wow animate__animated animate__fadeInUp "
                  data-wow-delay="0.2"
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                  }}
                >
                  {/* <h4>
                          {blog?.title}
                        </h4> */}
                  <a className="btn btn-lg p-3 w-100 py-3"

                    style={{
                      fontSize: '16px', backgroundColor: '#A67B5B',
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                    }}
                  >
                    <div className="py-3"> {blog?.title}</div>

                    <a href={`/blog/${blog?.blog_id}`} target="_blank">

                      <picture>
                        <source
                          srcset={blogurl + blog?.thumbnail}
                          media="(min-width: 1200px)"
                        />
                        <source
                          srcset={blogurl + blog?.thumbnail}
                          media="(min-width: 768px)"
                        />
                        <source
                          srcset={blogurl + blog?.thumbnail}
                          media="(max-width: 767px)"
                        />
                        <img
                          src={blogurl + blog?.thumbnail}
                          alt=""
                          className="page_speed_2025002599"
                          style={{ width: '350px', height: '223px' }}
                        />
                      </picture>
                    </a>
                    <div className="banner-button">
                      <a href={`/blog/${blog?.blog_id}`} className="btn btn-xs"
                        style={{ backgroundColor: '#A67B5B' }}

                      >
                        Read More <i className="fi-rs-arrow-small-right"></i>
                      </a>
                    </div>
                  </a>
                </div>
              </div>
            ))}

          </div>
        </div>
      </section>

    </div>
  );
}

export default BlogUser;
