import React, { useEffect, useState } from "react";
import { backend, categoryurl } from "../../../path";
import { Link, useNavigate } from "react-router-dom";
import "../../../App.css";

function HeaderMain(props) {
  const navigate = useNavigate();
  const [subcategory, setSubcategory] = useState(false);
  const [subcategoryItems, setSubcategoryItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [data, setData] = useState([]);
  const [name, setName] = useState([]);
  const [products, setProducts] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [wish, setWish] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isMobileHeaderActive, setMobileHeaderActive] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  // Function to toggle the visibility of the mobile header
  const toggleMobileHeader = () => {
    setMobileHeaderActive(!isMobileHeaderActive);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategorywithsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchProducts = () => {
    setLoading(true);

    fetch(backend + "getallproductsfrontend", {
      method: "GET",
    })
      .then((response) => {
        if (response.status === 302) {
          const redirectUrl = response.headers.get('Location');
          console.error(`Redirecting to: ${redirectUrl}`);
          throw new Error(`Redirection to ${redirectUrl}`);
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchProducts();
  }, [selectedCategory]);



  const handleSubCategory = (id) => {
    const selectedCategory = data.find((item) => item.category_id === id);
    if (selectedCategory) {
      setSubcategoryItems(selectedCategory.subcategory || []);
    }
    setSubcategory(true);
    setSelectedCategory(id);
  };

  const handleSubCategoryRoute = (subCategoryId) => {
    // sessionStorage.setItem("subCategoryId", subCategoryId);
    toggleDropdown();
    props?.SearchFetch()
    navigate(`/subcategory/${subCategoryId}`);
    // window.location.reload();
  };

  const handleCategoryMain = (cat) => {
    // sessionStorage.setItem("categoryId", cat);
    // setSubcategory(false)
    props?.SearchFetch()
    navigate(`/category/${cat}`);
    // window.location.reload();
  };


  useEffect(() => {
    if(authenticated){
    fetchDataCart();
    fetchDataWish();
    }
  }, [authenticated, props.cartChange, props.wishChange]);

  const fetchDataCart = () => {
    setLoading(true);

    fetch(backend + `getcartbyuseriduserside`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCartData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchDataWish = () => {
    setLoading(true);

    fetch(backend + `getwishlistbyuseriduserside`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setWish(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchName = () => {
    setLoading(true);

    const url = authenticated?.usertype === 1 ? 'getusername' : 'getadminname';

    fetch(backend + url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setName(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if(authenticated){
    fetchName();
    }
  }, [authenticated]);

  const [openCategory, setOpenCategory] = useState(null);

  // Function to handle category click
  const handleCategoryClick = (categoryId) => {
    // Toggle the category: if already open, close it; if closed, open it
    setOpenCategory(openCategory === categoryId ? null : categoryId);
  };

  const getOptions = (inputValue) => {
    setSearch(inputValue);
    if (inputValue.trim() === "") {
      setFilteredOptions([]);
      return;
    }

    // Filter products based on input value
    const filtered = products.filter(product =>
      product.product_name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered.slice(0, 18));
  };
  const handleSelectSearch = (inputValue) => {
    props.setSearch(inputValue)
    setFilteredOptions([]);
  };

  const handleProductRoute = (productId) => {
    setSearch('')
    props.setSearch('')
    setFilteredOptions([]);
    navigate(`/product/${productId}`);
  };
  return (
    <>

      {authenticated?.usertype === 1 ? (
        <div className="bg-light position-static">
          <header className="header-area header-style-1 header-height-2">
            <div className="header-middle header-middle-ptb-1 d-none d-lg-block fixed-top bg-light">
              <div className="container">
                <div className="header-wrap">
                  <div className="logo logo-width-1">
                    <Link to="/" onClick={() => props?.SearchFetch()}>
                      <img
                        src="/storage/general/ks-logo32.png"
                        alt="Kerala Specials-Logo"
                      />
                    </Link>
                  </div>
                  <div className="header-right">
                    <div className="search-style-2">
                      <div className="form--quick-search" onSubmit={props.handleSearchSubmit}>
                        <div className="form-group--icon position-relative">
                        </div>
                        <input
                          type="text"
                          className="input-search-product"
                          placeholder="Search for items...."
                          // value={props.search}
                          value={search}
                          onChange={(e) => getOptions(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSelectSearch(search);
                            }
                          }}                          // onChange={(e) => props.setSearch(e.target.value)}
                          autocomplete="off"
                        />
                        <div className="position-relative">
                          <div
                            className=" position-absolute"
                            style={{ bottom: '2px', right: '2px' }}
                          >
                            <button
                              type="button"
                              className=""
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                              onClick={() => handleSelectSearch(search)}
                            >
                              Search
                            </button>
                          </div>
                        </div>

                        <div className="panel--search-result">

                        </div>
                      </div>
                    </div>
                    {filteredOptions.length > 0 && (
                      <div className="options-list gap-2"
                        style={{ position: 'absolute', alignContent: 'center', top: '80%' }}>
                        {filteredOptions.map((option, index) => (
                          <div key={index} className="option-item border border-rounded p-1 ms-4"
                            style={{ borderRadius: '10px', color: '#fff', fontSize: '15px', backgroundColor: '#3bb77e', cursor: 'pointer' }}
                            onClick={() => handleProductRoute(option?.product_slug)}
                          >
                            {option.product_name}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="header-action-right">
                      <div className="header-action-2">
                      <div className="header-action-icon-2">
                          <Link to="/onamgift">
                            <span className="lable">                          
                              <button
                              type="button"
                              className="hover-up"
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                            >
                              ONAM 2K24
                            </button></span>
                          </Link>
                        </div>
                        <div className="header-action-icon-2">
                          <Link to="/wishlist">
                            <img
                              className="svgInject"
                              alt="Wishlist"
                              src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                            />
                            <span className="pro-count blue wishlist-count">
                              {wish?.length}
                            </span>
                          </Link>
                          <Link to="/wishlist">
                            <span className="lable">Wishlist</span>
                          </Link>
                        </div>
                        <div className="header-action-icon-2">
                          <Link className="mini-cart-icon" to="/cart">
                            <img
                              alt="Cart"
                              src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                            />
                            <span className="pro-count blue">
                              {cartData?.length}
                            </span>
                          </Link>
                          <Link to="/cart">
                            <span className="lable">Cart</span>
                          </Link>
                          {cartData?.length < 1 && (
                            <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                              <span>No products in the cart.</span>
                            </div>
                          )}
                        </div>
                        <div className="header-action-icon-2">
                          <Link >
                            <img
                              className="svgInject rounded-circle"
                              alt="Account"
                              src="/themes/nest/imgs/theme/icons/icon-user.svg"
                            />
                          </Link>
                          <Link to="/" onClick={() => props?.SearchFetch()}>
                            <span className="lable me-1">
                              {authenticated
                                ? `${name}`
                                : "Account"}
                            </span>
                          </Link>
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            <ul>
                              {!authenticated ? (
                                <>
                                  {" "}
                                  <div className="btn-wrap"><Link to="/login" onClick={() => props?.SearchFetch()}> <button className="login-btn">LOGIN</button></Link>
                                    <Link to="/register"><button className="login-btn1">REGISTER</button></Link></div>
                                </>
                              ) : (
                                <>
                                  <div className="btn-wrap"><Link to="/myorders" onClick={() => props?.SearchFetch()}> <button className="login-btn">MY ORDERS</button></Link></div>
                                  <div className="btn-wrap" onClick={handleLogout}><Link to="/" onClick={() => props?.SearchFetch()}> <button className="login-btn">LOGOUT</button></Link></div>

                                </>)}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

              </div>
            </div>
            <div className="header-bottom header-bottom-bg-color d-none d-md-block sticky-bar sticky-top fixed-top">
              <div className="container">
                <div className="header-wrap header-space-between position-relative">
                  <div className="logo logo-width-1 d-block d-lg-none">
                    {/* <Link to="/" onClick={() => props?.SearchFetch()}>
                      <img
                        src="/storage/general/ks-logo322.png"
                        alt="Kerala Specials"
                      />
                    </Link> */}
                    <div className="form--quick-search" onSubmit={props.handleSearchSubmit}>
                      <div className="form-group--icon position-relative">
                      </div>
                      <input
                        type="text"
                        className="input-search-product"
                        placeholder="Search for items...."
                        // value={props.search}
                        value={search}
                        onChange={(e) => getOptions(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSelectSearch(search);
                          }
                        }}                        // onChange={(e) => props.setSearch(e.target.value)}
                        autocomplete="off"
                      />
                      <div className="position-relative">
                        <div
                          className=" position-absolute"
                          style={{ bottom: '2px', right: '2px' }}
                        >
                          <button
                            type="button"
                            className=""
                            style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                            onClick={() => handleSelectSearch(search)}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    {filteredOptions.length > 0 && (
                      <div className="options-list gap-2"
                        style={{ position: 'absolute', alignContent: 'center', top: '80%' }}>
                        {filteredOptions.map((option, index) => (
                          <div key={index} className="option-item border border-rounded p-1 ms-4"
                            style={{ borderRadius: '10px', color: '#fff', fontSize: '15px', backgroundColor: '#3bb77e', cursor: 'pointer' }}
                            onClick={() => handleProductRoute(option?.product_slug)}
                          >
                            {option.product_name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="header-nav d-none d-lg-flex">
                    <div className="main-categories-wrap d-none d-lg-block" onMouseLeave={toggleDropdown}
                    >
                      <Link
                        className="categories-button-active"
                        /* <Link
                      className={`categories-button ${
                        isDropdownVisible ? "active" : ""
                      }`} */
                        // to="#"
                        onMouseEnter={toggleDropdown}
                      >
                        <span className="fi-rs-apps"></span>
                        <span className="et">Browse</span> All Categories
                        <i className="fi-rs-angle-down"></i>
                      </Link>
                      {isDropdownVisible ? (
                        <div>
                          <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading open ">
                            <div className="d-flex categories-dropdown-inner">
                              <ul>
                                {data.map((item, i) => (
                                  <li
                                    style={{
                                      backgroundColor:
                                        selectedCategory === item.category_id &&
                                          subcategory
                                          ? "#e1f0da"
                                          : "transparent",
                                    }}
                                    key={i}
                                    onMouseEnter={() =>
                                      handleSubCategory(item.category_id)
                                    }
                                    // onClick={() => setSubcategory(false)}
                                    onClick={() => { handleCategoryMain(item.category_slug) }}
                                  >
                                    <Link to="">
                                      <img
                                        src={categoryurl + item.logo}
                                        width={30}
                                        height={30}
                                      />{" "}
                                      {item.category_name}
                                    </Link>
                                  </li>
                                ))}
                                {subcategory && (
                                  <div className="categories-sub-main">
                                    <ul className="ml-3">
                                      {subcategoryItems.length > 0 ?
                                        subcategoryItems.map((subItem, index) => (
                                          <li key={index}>
                                            <div
                                              // to={`/subcategory/${subItem.sub_category_id}`}
                                              onClick={() =>
                                                handleSubCategoryRoute(
                                                  subItem.sub_category_slug
                                                )
                                              }
                                            // to={"/product-list"}
                                            >
                                              {subItem.sub_category_name}
                                            </div>
                                          </li>
                                        )) :
                                        (
                                          <ul className="ml-3">
                                            <li>coming soon</li>
                                          </ul>

                                        )}
                                    </ul>
                                  </div>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                      <nav>
                        <ul>
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self"
                            >
                              Home {/* <i className="fi-rs-angle-down"></i> */}
                            </Link>
                            {/* <ul className="sub-menu">
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              {" "}
                              Home 1{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-2.html" target="_self">
                              {" "}
                              Home 2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-3.html" target="_self">
                              {" "}
                              Home 3{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-4.html" target="_self">
                              {" "}
                              Home 4{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-5.html" target="_self">
                              {" "}
                              Home 5{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-6.html" target="_self">
                              {" "}
                              Home 6{" "}
                            </Link>
                          </li>
                        </ul> */}
                          </li>
                          <li>
                            <Link to="/about-us" target="_self"
                              onClick={() => props?.SearchFetch()}

                            >
                              About Us
                            </Link>
                            {/* <ul className="sub-menu">
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              {" "}
                              Home 1{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-2.html" target="_self">
                              {" "}
                              Home 2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-3.html" target="_self">
                              {" "}
                              Home 3{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-4.html" target="_self">
                              {" "}
                              Home 4{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-5.html" target="_self">
                              {" "}
                              Home 5{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-6.html" target="_self">
                              {" "}
                              Home 6{" "}
                            </Link>
                          </li>
                        </ul> */}
                          </li>
                          <li>
                            <Link to="/blog" target="_self" onClick={() => props?.SearchFetch()}
                            >
                              Blog
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us" target="_self" onClick={() => props?.SearchFetch()}
                            >
                              {" "}
                              Contact Us{" "}
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="mobile-social-icon d-none d-lg-flex">
                    <Link to="https://www.facebook.com/share/XM1oRDDkvagcQKW9/?mibextid=qi2Omg" title="Facebook" target="blank"
                    >
                      <img
                        src="/storage/general/facebook.png"
                        loading="lazy"
                        alt="Facebook"
                      />
                    </Link>

                    <Link to="https://www.instagram.com/keralaspecial?igsh=YTdxY3ByYnk2ZGpn" title="Instagram" target="blank">
                      <img
                        src="/storage/general/instagram.png"
                        loading="lazy"
                        alt="Instagram"
                      />
                    </Link>
                    <Link to="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g&s=09" title="X" target="blank">
                      <img
                        src="/storage/general/x.png"
                        loading="lazy"
                        alt="X"
                      />
                    </Link>
                    <Link to="https://youtube.com/@keralaspecial5256?si=XHIcNHk41dBE2Ast" title="Youtube" target="blank">
                      <img
                        src="/storage/general/youtube.png"
                        loading="lazy"
                        alt="Youtube"
                      />
                    </Link>
                  </div>
                  <div className="hotline d-none d-lg-flex">
                    <img
                      src="/themes/nest/imgs/theme/icons/icon-headphone.svg"
                      alt="hotline"
                    />
                    <p>
                      +91-7356629493<span>9 AM to 6 PM</span>
                    </p>
                  </div>
                  <div
                    className="header-action-icon-2 d-block d-lg-none"
                    onClick={toggleMobileHeader}
                  >
                    <div className="burger-icon burger-icon-white">
                      <span className="burger-icon-top"></span>
                      <span className="burger-icon-mid"></span>
                      <span className="burger-icon-bottom"></span>
                    </div>
                  </div>
                  <div className="header-action-right d-block d-lg-none">
                    <div className="header-action-2">
                      <div className="header-action-icon-2">
                        <Link to="/wishlist">
                          <img
                            alt="Wishlist"
                            src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                          />
                          <span className="pro-count white wishlist-count">
                            {wish?.length}
                          </span>
                        </Link>
                      </div>
                      <div className="header-action-icon-2">
                        <Link className="mini-cart-icon" to="/cart">
                          <img
                            alt="Cart"
                            src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                          />
                          <span className="pro-count white">{cartData?.length}</span>
                        </Link>
                        {cartData?.length < 1 && (
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                            <span>No products in the cart.</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-bottom header-bottom-bg-color d-block d-md-none sticky-bar fixed-top mb-5">
              <div className="container">
                <div className="header-wrap header-space-between position-relative">
                  <div className="logo logo-width-1 d-block d-lg-none">
                    {/* <Link to="/" onClick={() => props?.SearchFetch()}>
                      <img
                        src="/storage/general/ks-logo322.png"
                        alt="Kerala Specials"
                      />
                    </Link> */}
                    <div className="form--quick-search" onSubmit={props.handleSearchSubmit}>
                      <div className="form-group--icon position-relative">
                      </div>
                      <input
                        type="text"
                        className="input-search-product"
                        placeholder="Search for items...."
                        // value={props.search}
                        value={search}
                        onChange={(e) => getOptions(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSelectSearch(search);
                          }
                        }}                        // onChange={(e) => props.setSearch(e.target.value)}
                        autocomplete="off"
                      />
                      <div className="position-relative">
                        <div
                          className=" position-absolute"
                          style={{ bottom: '1px', right: '1px' }}
                        >
                          <button
                            type="button"
                            className=""
                            style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '10px', border: 'none', height: '26px', width: '50px', bottom: '5px' }}
                            onClick={() => handleSelectSearch(search)}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {filteredOptions.length > 0 && (
                    <div className="options-list gap-2"
                      style={{ position: 'absolute', alignContent: 'center', top: '100%' }}>
                      {filteredOptions.map((option, index) => (
                        <div key={index} className="option-item border border-rounded p-1 ms-4"
                          style={{ borderRadius: '10px', color: '#fff', fontSize: '15px', backgroundColor: '#3bb77e', cursor: 'pointer' }}
                          onClick={() => handleProductRoute(option?.product_slug)}
                          >
                          {option.product_name}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="header-nav d-none d-lg-flex">
                    <div className="main-categories-wrap d-none d-lg-block" onMouseLeave={toggleDropdown}
                    >
                      <Link
                        className="categories-button-active"
                        /* <Link
                      className={`categories-button ${
                        isDropdownVisible ? "active" : ""
                      }`} */
                        // to="#"
                        onMouseEnter={toggleDropdown}
                      >
                        <span className="fi-rs-apps"></span>
                        <span className="et">Browse</span> All Categories
                        <i className="fi-rs-angle-down"></i>
                      </Link>
                      {isDropdownVisible ? (
                        <div>
                          <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading open ">
                            <div className="d-flex categories-dropdown-inner">
                              <ul>
                                {data.map((item, i) => (
                                  <li
                                    style={{
                                      backgroundColor:
                                        selectedCategory === item.category_id &&
                                          subcategory
                                          ? "#e1f0da"
                                          : "transparent",
                                    }}
                                    key={i}
                                    onMouseEnter={() =>
                                      handleSubCategory(item.category_id)
                                    }
                                    // onClick={() => setSubcategory(false)}
                                    onClick={() => handleCategoryMain(item.category_slug)}
                                  >
                                    <Link to="">
                                      <img
                                        src={categoryurl + item.logo}
                                        width={30}
                                        height={30}
                                      />{" "}
                                      {item.category_name}
                                    </Link>
                                  </li>
                                ))}
                                {subcategory && (
                                  <div className="categories-sub-main">
                                    <ul className="ml-3">
                                      {subcategoryItems.length > 0 ?
                                        subcategoryItems.map((subItem, index) => (
                                          <li key={index}>
                                            <div
                                              // to={`/subcategory/${subItem.sub_category_id}`}
                                              onClick={() =>
                                                handleSubCategoryRoute(
                                                  subItem.sub_category_slug
                                                )
                                              }
                                            // to={"/product-list"}
                                            >
                                              {subItem.sub_category_name}
                                            </div>
                                          </li>
                                        )) :
                                        (
                                          <ul className="ml-3">
                                            <li>coming soon</li>
                                          </ul>

                                        )}
                                    </ul>
                                  </div>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                      <nav>
                        <ul>
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self"
                            >
                              Home {/* <i className="fi-rs-angle-down"></i> */}
                            </Link>
                            {/* <ul className="sub-menu">
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              {" "}
                              Home 1{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-2.html" target="_self">
                              {" "}
                              Home 2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-3.html" target="_self">
                              {" "}
                              Home 3{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-4.html" target="_self">
                              {" "}
                              Home 4{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-5.html" target="_self">
                              {" "}
                              Home 5{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-6.html" target="_self">
                              {" "}
                              Home 6{" "}
                            </Link>
                          </li>
                        </ul> */}
                          </li>
                          <li>
                            <Link to="/about-us" target="_self" onClick={() => props?.SearchFetch()}
                            >
                              About Us
                            </Link>
                            {/* <ul className="sub-menu">
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              {" "}
                              Home 1{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-2.html" target="_self">
                              {" "}
                              Home 2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-3.html" target="_self">
                              {" "}
                              Home 3{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-4.html" target="_self">
                              {" "}
                              Home 4{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-5.html" target="_self">
                              {" "}
                              Home 5{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-6.html" target="_self">
                              {" "}
                              Home 6{" "}
                            </Link>
                          </li>
                        </ul> */}
                          </li>
                          <li>
                            <Link to="/blog" target="_self">
                              Blog
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us" target="_self">
                              {" "}
                              Contact Us{" "}
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="hotline d-none d-lg-flex">
                    <img
                      src="/themes/nest/imgs/theme/icons/icon-headphone.svg"
                      alt="hotline"
                    />
                    <p>
                      +91-7356629493<span>9 AM to 6 PM</span>
                    </p>
                  </div>
                  <div
                    className="header-action-icon-2 d-block d-lg-none"
                    onClick={toggleMobileHeader}
                  >
                    <div className="burger-icon burger-icon-white">
                      <span className="burger-icon-top"></span>
                      <span className="burger-icon-mid"></span>
                      <span className="burger-icon-bottom"></span>
                    </div>
                  </div>
                  <div className="header-action-right d-block d-lg-none">
                    <div className="header-action-2">
                      <div className="header-action-icon-2">
                        <Link to="/wishlist">
                          <img
                            alt="Wishlist"
                            src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                          />
                          <span className="pro-count white wishlist-count">
                            {wish?.length}
                          </span>
                        </Link>
                      </div>
                      <div className="header-action-icon-2">
                        <Link className="mini-cart-icon" to="/cart">
                          <img
                            alt="Cart"
                            src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                          />
                          <span className="pro-count white">{cartData?.length}</span>
                        </Link>
                        {cartData?.length < 1 && (
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                            <span>No products in the cart.</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {isMobileHeaderActive && (
            <div className="mobile-header-active mobile-header-wrapper-style sidebar-visible">
              <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-top">
                  <div className="mobile-header-logo">
                    <Link to="/" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}>
                      <img
                        src="/storage/general/ks-logo32.png"
                        alt="Kerala Special"
                      />
                    </Link>
                  </div>
                  <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                    <button
                      className="close-style search-close"
                      onClick={toggleMobileHeader}
                    >
                      <i className="icon-top"></i>
                      <i className="icon-bottom"></i>
                    </button>
                  </div>
                  <div className="mobile-social-icon d-flex d-lg-block justify-content-center mt-3 w-100">
                    <Link to="https://www.facebook.com/share/XM1oRDDkvagcQKW9/?mibextid=qi2Omg" title="Facebook" target="blank"
                    >
                      <img
                        src="/storage/general/facebook.png"
                        loading="lazy"
                        alt="Facebook"
                      />
                    </Link>

                    <Link to="https://www.instagram.com/keralaspecial?igsh=YTdxY3ByYnk2ZGpn" title="Instagram" target="blank">
                      <img
                        src="/storage/general/instagram.png"
                        loading="lazy"
                        alt="Instagram"
                      />
                    </Link>
                    <Link to="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g&s=09" title="X" target="blank">
                      <img
                        src="/storage/general/x.png"
                        loading="lazy"
                        alt="X"
                      />
                    </Link>
                    <Link to="https://youtube.com/@keralaspecial5256?si=XHIcNHk41dBE2Ast" title="Youtube" target="blank">
                      <img
                        src="/storage/general/youtube.png"
                        loading="lazy"
                        alt="Youtube"
                      />
                    </Link>
                  </div>
                  
                </div>
                <div className="p-3" style={{ borderBottom: '1px solid #F1F1F1' }}>
                  {!authenticated ? (
                    <div className="d-flex justify-content-around">
                      <div className="btn-wrap2"><Link to="/login" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}> <button className="login-btn2">LOGIN</button></Link>
                      </div>
                      <div className="btn-wrap">                          <Link to="/onamgift">
                            <span className="lable">                          
                              <button
                              type="button"
                              className="hover-up"
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                              onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}
                            >
                              ONAM 2K24
                            </button></span>
                          </Link></div>
                      <div className="btn-wrap2">
                        <Link to="/register" onClick={toggleMobileHeader}><button className="login-btn2">REGISTER</button></Link></div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-around">
                      <div className="btn-wrap2" onClick={handleLogout} ><Link to="/" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}> <button className="login-btn2">LOGOUT</button></Link></div>
                      <div className="btn-wrap">                          <Link to="/onamgift">
                            <span className="lable">                          
                              <button
                              type="button"
                              className="hover-up"
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                              onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}
                            >
                              ONAM 2K24
                            </button></span>
                          </Link></div>
                      <div className="btn-wrap"><Link to="/myorders" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}> <button className="login-btn2">MY ORDERS</button></Link></div>

                    </div>
                  )}
                </div>

                <div className="mobile-header-content-area">
                  <div className="mobile-menu-wrap mobile-header-border">
                    <nav>
                      <ul className="mobile-menu">
                        <li className=" menu-item-has-children ">
                          <span className="menu-expand"></span>
                          <Link to="/" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }} target="_self">
                            <div>Home</div>
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <span className="menu-expand"></span>
                          <Link to="" target="_self" onClick={toggleMobileHeader}>
                            {/* <i>
                              <Icon icon="tabler:category-plus" color="#65B741" width="14" height="14" />
                            </i> */}
                            All Categories
                          </Link>
                          <ul className="dropdown">
                            {data.map((item) => (
                              <li key={item.category_id} >
                                <div className="d-flex justify-content-between">
                                  <Link
                                    to={`/category/${item.category_slug}`}
                                    onClick={toggleMobileHeader}
                                    className="d-flex justify-content-start gap-3 my-auto"
                                    style={{ verticalAlign: 'middle' }}
                                  >
                                    <img
                                      src={categoryurl + item.logo}
                                      width={30}
                                      height={30}
                                      alt={item.category_name}
                                    />
                                    <div className="p-2">{item.category_name}</div>
                                  </Link>

                                  <div className="p-2">
                                    {!openCategory ?
                                      <svg xmlns="http://www.w3.org/2000/svg" onClick={(e) => {
                                        e.preventDefault(); // Prevent the default anchor click behavior
                                        handleCategoryClick(item.category_id);
                                      }} width="1em" height="1em" viewBox="0 0 48 48"><defs><mask id="ipSDownC0"><g fill="none" strokeLinejoin="round" strokeWidth={4}><path fill="#fff" stroke="#fff" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"></path><path stroke="#000" strokeLinecap="round" d="m33 21l-9 9l-9-9"></path></g></mask></defs><path fill="#3bb77e" d="M0 0h48v48H0z" mask="url(#ipSDownC0)"></path></svg>
                                      :
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                        onClick={(e) => {
                                          e.preventDefault(); // Prevent the default anchor click behavior
                                          handleCategoryClick(item.category_id);
                                        }} width="1em" height="1em" viewBox="0 0 48 48"><defs><mask id="ipSUpC0"><g fill="none" strokeWidth={4}><path stroke="#fff" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z" clipRule="evenodd"></path><path fill="#fff" stroke="#fff" strokeLinejoin="round" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"></path><path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="m33 27l-9-9l-9 9"></path></g></mask></defs><path fill="#3bb77e" d="M0 0h48v48H0z" mask="url(#ipSUpC0)"></path></svg>
                                    }</div></div>
                                {openCategory === item.category_id && (
                                  <ul className="sub-menu">
                                    {item.subcategory.map((sub) => (
                                      <li key={sub.sub_category_id}>
                                        <Link
                                          to={`/subcategory/${sub.sub_category_slug}`}
                                          onClick={toggleMobileHeader}
                                        >
                                          {sub.sub_category_name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className=" menu-item-has-children ">
                          <span className="menu-expand"></span>
                          <Link to="/about-us" target="_self" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}>
                            <div>About Us</div>
                          </Link>
                        </li>
                        <li className=" menu-item-has-children ">
                          <span className="menu-expand"></span>
                          <Link to="/blog" target="_self" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}>
                            <div>Blog</div>
                          </Link>
                        </li>
                        <li className=" menu-item-has-children">
                          <span className="menu-expand"></span>
                          <Link to="/contact-us" target="_self" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}>
                            <div>Contact Us</div>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="mobile-social-icon mb-50">
                    <p className="mb-15 font-heading h6 me-2">Follow Us</p>
                    <Link to="https://www.facebook.com/share/XM1oRDDkvagcQKW9/?mibextid=qi2Omg" title="Facebook">
                      <img
                        src="/storage/general/facebook.png"
                        loading="lazy"
                        alt="Facebook"
                      />
                    </Link>

                    <Link to="https://www.instagram.com/keralaspecial?igsh=YTdxY3ByYnk2ZGpn" title="Instagram">
                      <img
                        src="/storage/general/instagram.png"
                        loading="lazy"
                        alt="Instagram"
                      />
                    </Link>
                    <Link to="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g&s=09" title="X">
                      <img
                        src="/storage/general/x.png"
                        loading="lazy"
                        alt="X"
                      />
                    </Link>
                    <Link to="https://youtube.com/@keralaspecial5256?si=XHIcNHk41dBE2Ast" title="Youtube">
                      <img
                        src="/storage/general/youtube.png"
                        loading="lazy"
                        alt="Youtube"
                      />
                    </Link>
                  </div>
                  <div className="site-copyright">
                    Copyright : Kitsor Pvt Ltd (Keralaspecial.in ). All rights reserved.
                    Powered by GoTech Solutions.                </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : authenticated?.usertype === 0 ? (
        <div className="header-middle header-middle-ptb-1 d-none d-lg-block fixed-top bg-light">
          <div className="container">
            <div className="header-wrap">
              <div className="logo logo-width-1">
                <Link to="/" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}>
                  <img
                    src="/storage/general/ks-logo32.png"
                    alt="Kerala Specials-Logo"
                  />
                </Link>
              </div>
              <div className="d-flex justify-content-end w-100">
                <div className="header-action-right">
                  <div className="header-action-2">
                  <div className="header-action-icon-2">
                          <Link to="/onamgift">
                            <span className="lable">                          
                              <button
                              type="button"
                              className="hover-up"
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                            >
                              ONAM 2K24
                            </button></span>
                          </Link>
                        </div>
                    <div className="header-action-icon-2">
                      <Link to="/wishlist">
                        <img
                          className="svgInject"
                          alt="Wishlist"
                          src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                        />
                        <span className="pro-count blue wishlist-count">
                          {wish?.length}
                        </span>
                      </Link>
                      <Link to="/wishlist">
                        <span className="lable">Wishlist</span>
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link className="mini-cart-icon" to="/cart">
                        <img
                          alt="Cart"
                          src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                        />
                        <span className="pro-count blue">
                          {cartData?.length}
                        </span>
                      </Link>
                      <Link to="/cart">
                        <span className="lable">Cart</span>
                      </Link>
                      {cartData?.length < 1 && (
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                          <span>No products in the cart.</span>
                        </div>
                      )}
                    </div>
                    <div className="header-action-icon-2">
                      <Link>
                        <img
                          className="svgInject rounded-circle"
                          alt="Account"
                          src="/themes/nest/imgs/theme/icons/icon-user.svg"
                        />
                      </Link>
                      <Link to="/" onClick={() => props?.SearchFetch()}>
                        <span className="lable me-1">
                          {authenticated
                            ? `${name}`
                            : "Account"}
                        </span>
                      </Link>
                      <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                        <ul>
                          {!authenticated ? (
                            <>
                              <div className="btn-wrap"><Link to="/login" onClick={() => props?.SearchFetch()}> <button className="login-btn">LOGIN</button></Link>
                                <Link to="/register"><button className="login-btn1">REGISTER</button></Link></div>
                            </>
                          ) : (
                            <div className="btn-wrap" onClick={handleLogout}><Link to="/" onClick={() => props?.SearchFetch()}> <button className="login-btn">LOGOUT</button></Link></div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      ) : authenticated?.usertype === null ? (
        <div className="header-middle header-middle-ptb-1 d-none d-lg-block fixed-top bg-light">
          <div className="container">
            <div className="header-wrap">
              <div className="logo logo-width-1">
                <Link to="/" onClick={() => props?.SearchFetch()}>
                  <img
                    src="/storage/general/ks-logo32.png"
                    alt="Kerala Specials-Logo"
                  />
                </Link>
              </div>
              <div className="d-flex justify-content-end w-100">
                <div className="header-action-right">
                  <div className="header-action-2">
                  <div className="header-action-icon-2">
                          <Link to="/onamgift">
                            <span className="lable">                          
                              <button
                              type="button"
                              className="hover-up"
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                            >
                              ONAM 2K24
                            </button></span>
                          </Link>
                        </div>
                    <div className="header-action-icon-2">
                      <Link to="/wishlist">
                        <img
                          className="svgInject"
                          alt="Wishlist"
                          src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                        />
                        <span className="pro-count blue wishlist-count">
                          0{" "}
                        </span>
                      </Link>
                      <Link to="/wishlist">
                        <span className="lable">Wishlist</span>
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link className="mini-cart-icon" to="/cart">
                        <img
                          alt="Cart"
                          src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                        />
                        <span className="pro-count blue">
                          {cartData?.length}
                        </span>
                      </Link>
                      <Link to="/cart">
                        <span className="lable">Cart</span>
                      </Link>
                      {cartData?.length < 1 && (
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                          <span>No products in the cart.</span>
                        </div>
                      )}
                    </div>
                    <div className="header-action-icon-2">
                      <Link to="/login" onClick={() => props?.SearchFetch()}>
                        <img
                          className="svgInject rounded-circle"
                          alt="Account"
                          src="/themes/nest/imgs/theme/icons/icon-user.svg"
                        />
                      </Link>
                      <Link to="/" onClick={() => props?.SearchFetch()}>
                        <span className="lable me-1">
                          {authenticated
                            ? `${name}`
                            : "Account"}
                        </span>
                      </Link>
                      <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                        <ul>
                          {!authenticated ? (
                            <>
                              <div className="btn-wrap"><Link to="/login" onClick={() => props?.SearchFetch()}> <button className="login-btn">LOGIN</button></Link>
                                <Link to="/register"><button className="login-btn1">REGISTER</button></Link></div>
                            </>
                          ) : (
                            <>
                              <div className="btn-wrap" onClick={handleLogout}><Link to="/" onClick={() => props?.SearchFetch()}> <button className="login-btn">LOGOUT</button></Link></div>

                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-light position-static">
          <header className="header-area header-style-1 header-height-2">
            {/* <div className="mobile-promotion">
              <span>
                Grand opening, <strong>up to 15%</strong> off all items. Only{" "}
                <strong>3 days</strong> leftss
              </span>
            </div> */}

            <div className="header-middle header-middle-ptb-1 d-none d-lg-block fixed-top bg-light">
              <div className="container">
                <div className="header-wrap">
                  <div className="logo logo-width-1">
                    <Link to="/" onClick={() => props?.SearchFetch()}>
                      <img
                        src="/storage/general/ks-logo32.png"
                        alt="Kerala Specials-Logo"
                      />
                    </Link>
                  </div>
                  <div className="header-right">
                    <div className="search-style-2">
                      <div className="form--quick-search" onSubmit={props.handleSearchSubmit}>
                        <div className="form-group--icon position-relative">
                        </div>
                        <input
                          type="text"
                          className="input-search-product"
                          placeholder="Search for items...."
                          value={search}
                          onChange={(e) => getOptions(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSelectSearch(search);
                            }
                          }}
                          autoComplete="off"
                        />
                        <div className="position-relative">
                          <div
                            className=" position-absolute"
                            style={{ bottom: '2px', right: '2px' }}
                          >
                            <button
                              type="button"
                              className=""
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                              onClick={() => handleSelectSearch(search)}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {filteredOptions.length > 0 && (
                      <div className="options-list gap-2"
                        style={{ position: 'absolute', alignContent: 'center', top: '80%' }}>
                        {filteredOptions.map((option, index) => (
                          <div key={index} className="option-item border border-rounded p-1 ms-4"
                            style={{ borderRadius: '10px', color: '#fff', fontSize: '15px', backgroundColor: '#3bb77e', cursor: 'pointer' }}
                            onClick={() => handleProductRoute(option?.product_slug)}
                          >
                            {option.product_name}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="header-action-right">
                      <div className="header-action-2">
                        <div className="header-action-icon-2">
                          <Link to="/onamgift">
                            <span className="lable">                        
                              <button
                              type="button"
                              className="hover-up"
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                            >
                              ONAM 2K24
                            </button></span>
                          </Link>
                        </div>
                        <div className="header-action-icon-2">
                          <Link to="/wishlist">
                            <img
                              className="svgInject"
                              alt="Wishlist"
                              src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                            />
                            <span className="pro-count blue wishlist-count">
                              {wish?.length}
                            </span>
                          </Link>
                          <Link to="/wishlist">
                            <span className="lable">Wishlist</span>
                          </Link>
                        </div>
                        <div className="header-action-icon-2">
                          <Link className="mini-cart-icon" to="/cart">
                            <img
                              alt="Cart"
                              src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                            />
                            <span className="pro-count blue">
                              {cartData?.length}
                            </span>
                          </Link>
                          <Link to="/cart">
                            <span className="lable">Cart</span>
                          </Link>
                          {cartData?.length < 1 && (
                            <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                              <span>No products in the cart.</span>
                            </div>
                          )}
                        </div>
                        <div className="header-action-icon-2">
                          <Link >
                            <img
                              className="svgInject rounded-circle"
                              alt="Account"
                              src="/themes/nest/imgs/theme/icons/icon-user.svg"
                            />
                          </Link>
                          <Link to="/" onClick={() => props?.SearchFetch()}>
                            <span className="lable me-1">
                              {authenticated
                                ? `${name}`
                                : "Account"}
                            </span>
                          </Link>
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            <ul>
                              {!authenticated ? (
                                <>
                                  {" "}
                                  <div className="btn-wrap"><Link to="/login" onClick={() => props?.SearchFetch()}> <button className="login-btn">LOGIN</button></Link>
                                    <Link to="/register"><button className="login-btn1">REGISTER</button></Link></div>
                                </>
                              ) : (
                                <>
                                  <div className="btn-wrap"><Link to="/myorders" onClick={() => props?.SearchFetch()}> <button className="login-btn">MY ORDERS</button></Link></div>
                                  <div className="btn-wrap" onClick={handleLogout}><Link to="/" onClick={() => props?.SearchFetch()}> <button className="login-btn">LOGOUT</button></Link></div>

                                </>)}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-bottom d-none d-md-block header-bottom-bg-color sticky-bar sticky-top fixed-top">
              <div className="container">

                <div className="header-wrap header-space-between position-relative">

                  <div className="logo logo-width-1 d-block d-lg-none">
                    {/* <Link to="/" onClick={() => props?.SearchFetch()}>
                      <img
                        src="/storage/general/ks-logo322.png"
                        alt="Kerala Specials"
                      />
                    </Link> */}
                    <div className="form--quick-search" onSubmit={props.handleSearchSubmit}>
                      <div className="form-group--icon position-relative">
                      </div>
                      <input
                        type="text"
                        className="input-search-product"
                        placeholder="Search for items..."
                        value={search}
                        onChange={(e) => getOptions(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSelectSearch(search);
                          }
                        }} autocomplete="off"
                        style={{ borderRadius: '5px' }}
                      />
                      <div className="panel--search-result"></div>
                    </div>

                  </div>
                  {filteredOptions.length > 0 && (
                    <div className="options-list gap-2 d-block d-md-none"
                      style={{ position: 'absolute', alignContent: 'center', top: '80%' }}>
                      {filteredOptions.map((option, index) => (
                        <div key={index} className="option-item border border-rounded p-1 ms-4"
                          style={{ borderRadius: '10px', color: '#fff', fontSize: '15px', backgroundColor: '#3bb77e', cursor: 'pointer' }}
                          onClick={() => handleProductRoute(option?.product_slug)}
                          >
                          {option.product_name}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="header-nav d-none d-lg-flex">
                    <div className="main-categories-wrap d-none d-lg-block" onMouseLeave={toggleDropdown}
                    >
                      <Link
                        className="categories-button-active"
                        /* <Link
                      className={`categories-button ${
                        isDropdownVisible ? "active" : ""
                      }`} */
                        // to="#"
                        onMouseEnter={toggleDropdown}
                      >
                        <span className="fi-rs-apps"></span>
                        <span className="et">Browse</span> All Categories
                        <i className="fi-rs-angle-down"></i>
                      </Link>
                      {isDropdownVisible ? (
                        <div>
                          <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading open ">
                            <div className="d-flex categories-dropdown-inner">
                              <ul>
                                {data.map((item, i) => (
                                  <li
                                    style={{
                                      backgroundColor:
                                        selectedCategory === item.category_id &&
                                          subcategory
                                          ? "#e1f0da"
                                          : "transparent",
                                    }}
                                    key={i}
                                    onMouseEnter={() =>
                                      handleSubCategory(item.category_id)
                                    }
                                    onClick={() => handleCategoryMain(item.category_slug)}
                                  >
                                    <Link to="">
                                      <img
                                        src={categoryurl + item.logo}
                                        width={30}
                                        height={30}
                                      />
                                      {item.category_name}
                                    </Link>
                                  </li>
                                ))}
                                {subcategory && (
                                  <div className="categories-sub-main">
                                    <ul className="ml-3">
                                      {subcategoryItems.length > 0 ?
                                        subcategoryItems.map((subItem, index) => (
                                          <li key={index}>
                                            <div
                                              // to={`/subcategory/${subItem.sub_category_id}`}
                                              onClick={() =>
                                                handleSubCategoryRoute(
                                                  subItem.sub_category_slug
                                                )
                                              }
                                            // to={"/product-list"}
                                            >
                                              {subItem.sub_category_name}
                                            </div>
                                          </li>
                                        )) :
                                        (
                                          <ul className="ml-3">
                                            <li>coming soon</li>
                                          </ul>

                                        )}
                                    </ul>
                                  </div>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                      <nav>
                        <ul>
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              Home {/* <i className="fi-rs-angle-down"></i> */}
                            </Link>
                            {/* <ul className="sub-menu">
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              {" "}
                              Home 1{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-2.html" target="_self">
                              {" "}
                              Home 2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-3.html" target="_self">
                              {" "}
                              Home 3{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-4.html" target="_self">
                              {" "}
                              Home 4{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-5.html" target="_self">
                              {" "}
                              Home 5{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-6.html" target="_self">
                              {" "}
                              Home 6{" "}
                            </Link>
                          </li>
                        </ul> */}
                          </li>
                          <li>
                            <Link to="/about-us" target="_self" onClick={() => props?.SearchFetch()}>
                              About Us
                            </Link>
                            {/* <ul className="sub-menu">
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              {" "}
                              Home 1{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-2.html" target="_self">
                              {" "}
                              Home 2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-3.html" target="_self">
                              {" "}
                              Home 3{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-4.html" target="_self">
                              {" "}
                              Home 4{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-5.html" target="_self">
                              {" "}
                              Home 5{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-6.html" target="_self">
                              {" "}
                              Home 6{" "}
                            </Link>
                          </li>
                        </ul> */}
                          </li>
                          <li>
                            <Link to="/blog" target="_self">
                              Blog
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us" target="_self">
                              {" "}
                              Contact Us{" "}
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="mobile-social-icon d-none d-lg-flex">
                    <Link to="https://www.facebook.com/share/XM1oRDDkvagcQKW9/?mibextid=qi2Omg" title="Facebook" target="blank"
                    >
                      <img
                        src="/storage/general/facebook.png"
                        loading="lazy"
                        alt="Facebook"
                      />
                    </Link>

                    <Link to="https://www.instagram.com/keralaspecial?igsh=YTdxY3ByYnk2ZGpn" title="Instagram" target="blank">
                      <img
                        src="/storage/general/instagram.png"
                        loading="lazy"
                        alt="Instagram"
                      />
                    </Link>
                    <Link to="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g&s=09" title="X" target="blank">
                      <img
                        src="/storage/general/x.png"
                        loading="lazy"
                        alt="X"
                      />
                    </Link>
                    <Link to="https://youtube.com/@keralaspecial5256?si=XHIcNHk41dBE2Ast" title="Youtube" target="blank">
                      <img
                        src="/storage/general/youtube.png"
                        loading="lazy"
                        alt="Youtube"
                      />
                    </Link>
                  </div>
                  <div className="hotline d-none d-lg-flex">

                    <img
                      src="/themes/nest/imgs/theme/icons/icon-headphone.svg"
                      alt="hotline"
                    />
                    <p>
                      91-7356629493<span>9 AM to 6 PM</span>
                    </p>
                  </div>
                  <div
                    className="header-action-icon-2 d-block d-lg-none"
                    onClick={toggleMobileHeader}
                  >
                    <div className="burger-icon burger-icon-white">
                      <span className="burger-icon-top"></span>
                      <span className="burger-icon-mid"></span>
                      <span className="burger-icon-bottom"></span>
                    </div>
                  </div>
                  <div className="header-action-right d-block d-lg-none">
                    <div className="header-action-2">
                      <div className="header-action-icon-2">
                        <Link to="/wishlist">
                          <img
                            alt="Wishlist"
                            src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                          />
                          <span className="pro-count white wishlist-count">
                            {wish?.length}
                          </span>
                        </Link>
                      </div>
                      <div className="header-action-icon-2">
                        <Link className="mini-cart-icon" to="/cart">
                          <img
                            alt="Cart"
                            src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                          />
                          <span className="pro-count white">{cartData?.length}</span>
                        </Link>
                        {cartData?.length < 1 && (
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                            <span>No products in the cart.</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-bottom d-block d-md-none header-bottom-bg-color sticky-bar fixed-top">
              <div className="container">

                <div className="header-wrap header-space-between position-relative">

                  <div className="logo logo-width-1 d-block d-lg-none">
                    {/* <Link to="/" onClick={() => props?.SearchFetch()}>
                      <img
                        src="/storage/general/ks-logo322.png"
                        alt="Kerala Specials"
                      />
                    </Link> */}
                    <div className="form--quick-search" onSubmit={props.handleSearchSubmit}>
                      <div className="form-group--icon position-relative">
                      </div>
                      <input
                        type="text"
                        className="input-search-product"
                        placeholder="Search for items...."
                        // value={props.search}
                        value={search}
                        onChange={(e) => getOptions(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSelectSearch(search);
                          }
                        }}                        // onChange={(e) => props.setSearch(e.target.value)}
                        autocomplete="off"
                      />
                      <div className="position-relative">
                        <div
                          className=" position-absolute"
                          style={{ bottom: '1px', right: '1px' }}
                        >
                          <button
                            type="button"
                            className=""
                            style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '10px', border: 'none', height: '26px', width: '50px', bottom: '5px' }}
                            onClick={() => handleSelectSearch(search)}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>


                  </div>
                  {filteredOptions.length > 0 && (
                    <div className="options-list gap-2"
                      style={{ position: 'absolute', alignContent: 'center', top: '80%' }}>
                      {filteredOptions.map((option, index) => (
                        <div key={index} className="option-item border border-rounded p-1 ms-4"
                          style={{ borderRadius: '10px', color: '#fff', fontSize: '15px', backgroundColor: '#3bb77e', cursor: 'pointer' }}
                          onClick={() => handleProductRoute(option?.product_slug)}
                          >
                          {option.product_name}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="header-nav d-none d-lg-flex">
                    <div className="main-categories-wrap d-none d-lg-block" onMouseLeave={toggleDropdown}
                    >
                      <Link
                        className="categories-button-active"
                        /* <Link
                      className={`categories-button ${
                        isDropdownVisible ? "active" : ""
                      }`} */
                        // to="#"
                        onMouseEnter={toggleDropdown}
                      >
                        <span className="fi-rs-apps"></span>
                        <span className="et">Browse</span> All Categories
                        <i className="fi-rs-angle-down"></i>
                      </Link>
                      {isDropdownVisible ? (
                        <div>
                          <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading open ">
                            <div className="d-flex categories-dropdown-inner">
                              <ul>
                                {data.map((item, i) => (
                                  <li
                                    style={{
                                      backgroundColor:
                                        selectedCategory === item.category_id &&
                                          subcategory
                                          ? "#e1f0da"
                                          : "transparent",
                                    }}
                                    key={i}
                                    onMouseEnter={() =>
                                      handleSubCategory(item.category_id)
                                    }
                                    onClick={() => handleCategoryMain(item.category_slug)}
                                  >
                                    <Link to="">
                                      <img
                                        src={categoryurl + item.logo}
                                        width={30}
                                        height={30}
                                      />
                                      {item.category_name}
                                    </Link>
                                  </li>
                                ))}
                                {subcategory && (
                                  <div className="categories-sub-main">
                                    <ul className="ml-3">
                                      {subcategoryItems.length > 0 ?
                                        subcategoryItems.map((subItem, index) => (
                                          <li key={index}>
                                            <div
                                              // to={`/subcategory/${subItem.sub_category_id}`}
                                              onClick={() =>
                                                handleSubCategoryRoute(
                                                  subItem.sub_category_slug
                                                )
                                              }
                                            // to={"/product-list"}
                                            >
                                              {subItem.sub_category_name}
                                            </div>
                                          </li>
                                        )) :
                                        (
                                          <ul className="ml-3">
                                            <li>coming soon</li>
                                          </ul>

                                        )}
                                    </ul>
                                  </div>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                      <nav>
                        <ul>
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              Home {/* <i className="fi-rs-angle-down"></i> */}
                            </Link>
                            {/* <ul className="sub-menu">
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              {" "}
                              Home 1{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-2.html" target="_self">
                              {" "}
                              Home 2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-3.html" target="_self">
                              {" "}
                              Home 3{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-4.html" target="_self">
                              {" "}
                              Home 4{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-5.html" target="_self">
                              {" "}
                              Home 5{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-6.html" target="_self">
                              {" "}
                              Home 6{" "}
                            </Link>
                          </li>
                        </ul> */}
                          </li>
                          <li>
                            <Link to="/about-us" target="_self" onClick={() => props?.SearchFetch()}>
                              About Us
                            </Link>
                            {/* <ul className="sub-menu">
                          <li>
                            <Link to="/" onClick={() => props?.SearchFetch()} target="_self">
                              {" "}
                              Home 1{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-2.html" target="_self">
                              {" "}
                              Home 2{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-3.html" target="_self">
                              {" "}
                              Home 3{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-4.html" target="_self">
                              {" "}
                              Home 4{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-5.html" target="_self">
                              {" "}
                              Home 5{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="homepage-6.html" target="_self">
                              {" "}
                              Home 6{" "}
                            </Link>
                          </li>
                        </ul> */}
                          </li>
                          <li>
                            <Link to="/blog" target="_self">
                              Blog
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us" target="_self">
                              {" "}
                              Contact Us{" "}
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="hotline d-none d-lg-flex">
                    <img
                      src="/themes/nest/imgs/theme/icons/icon-headphone.svg"
                      alt="hotline"
                    />
                    <p>
                      91-7356629493<span>9 AM to 6 PM</span>
                    </p>
                  </div>
                  <div
                    className="header-action-icon-2 d-block d-lg-none"
                    onClick={toggleMobileHeader}
                  >
                    <div className="burger-icon burger-icon-white">
                      <span className="burger-icon-top"></span>
                      <span className="burger-icon-mid"></span>
                      <span className="burger-icon-bottom"></span>
                    </div>
                  </div>
                  <div className="header-action-right d-block d-lg-none">
                    <div className="header-action-2">
                      <div className="header-action-icon-2">
                        <Link to="/wishlist">
                          <img
                            alt="Wishlist"
                            src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                          />
                          <span className="pro-count white wishlist-count">
                            {wish?.length}
                          </span>
                        </Link>
                      </div>
                      <div className="header-action-icon-2">
                        <Link className="mini-cart-icon" to="/cart">
                          <img
                            alt="Cart"
                            src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                          />
                          <span className="pro-count white">{cartData?.length}</span>
                        </Link>
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                          <span>No products in the cart.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* ---- */}
          {isMobileHeaderActive && (
            <div className="mobile-header-active mobile-header-wrapper-style sidebar-visible">
              <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-top">
                  <div className="mobile-header-logo">
                    <Link to="/" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}>
                      <img
                        src="/storage/general/ks-logo32.png"
                        alt="Kerala Special"
                      />
                    </Link>
                  </div>
                  <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                    <button
                      className="close-style search-close"
                      onClick={toggleMobileHeader}
                    >
                      <i className="icon-top"></i>
                      <i className="icon-bottom"></i>
                    </button>
                  </div>
                  <div className="mobile-social-icon d-flex d-lg-block justify-content-center mt-3 w-100">
                    <Link to="https://www.facebook.com/share/XM1oRDDkvagcQKW9/?mibextid=qi2Omg" title="Facebook" target="blank"
                    >
                      <img
                        src="/storage/general/facebook.png"
                        loading="lazy"
                        alt="Facebook"
                      />
                    </Link>

                    <Link to="https://www.instagram.com/keralaspecial?igsh=YTdxY3ByYnk2ZGpn" title="Instagram" target="blank">
                      <img
                        src="/storage/general/instagram.png"
                        loading="lazy"
                        alt="Instagram"
                      />
                    </Link>
                    <Link to="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g&s=09" title="X" target="blank">
                      <img
                        src="/storage/general/x.png"
                        loading="lazy"
                        alt="X"
                      />
                    </Link>
                    <Link to="https://youtube.com/@keralaspecial5256?si=XHIcNHk41dBE2Ast" title="Youtube" target="blank">
                      <img
                        src="/storage/general/youtube.png"
                        loading="lazy"
                        alt="Youtube"
                      />
                    </Link>
                  </div>
                </div>


                <div className="p-3" style={{ borderBottom: '1px solid #F1F1F1' }}>
                  {!authenticated ? (
                    <div className="d-flex justify-content-around">
                      <div className="btn-wrap2"><Link to="/login" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}> <button className="login-btn2">LOGIN</button></Link>
                      </div>
                      <div className="btn-wrap">                          <Link to="/onamgift">
                            <span className="lable">                          
                              <button
                              type="button"
                              className="hover-up"
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                              onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}
                            >
                              ONAM 2K24
                            </button></span>
                          </Link></div>
                      <div className="btn-wrap2">
                        <Link to="/register" onClick={toggleMobileHeader}><button className="login-btn2">REGISTER</button></Link></div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-around">

                      <div className="btn-wrap2" onClick={handleLogout} ><Link to="/" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}> <button className="login-btn2">LOGOUT</button></Link></div>
                      <div className="btn-wrap">                          <Link to="/onamgift">
                            <span className="lable">                          
                              <button
                              type="button"
                              className="hover-up"
                              style={{ backgroundColor: '#3bb77e', color: '#fff', borderRadius: '5px', border: 'none', height: '36px', width: '100px', bottom: '5px' }}
                              onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}
                            >
                              ONAM 2K24
                            </button></span>
                          </Link></div>
                      <div className="btn-wrap2" onClick={handleLogout}><Link to="/" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}> <button className="login-btn2">MY ORDERS</button></Link></div>

                    </div>
                  )}

                </div>

                <div className="mobile-header-content-area">

                  {/* <div className="mobile-search search-style-3 mobile-header-border">
                    <form
                      // action="https://nest.botble.com/products"
                      className="form--quick-search"
                    // data-ajax-url="https://nest.botble.com/ajax/search-products"
                    >
                      <input
                        type="text"
                        name="q"
                        className="input-search-product"
                        placeholder="Search for items..."
                        value={props.search}
                        onChange={(e) => props.setSearch(e.target.value)}
                        autocomplete="off"
                      />
                      <button type="submit">
                        <i className="fi-rs-search"></i>
                      </button>
                      <div className="panel--search-result"></div>
                    </form>
                  </div> */}
                  <div className="mobile-menu-wrap mobile-header-border">
                    <nav>
                      <ul className="mobile-menu">
                        <li className=" menu-item-has-children ">
                          <span className="menu-expand"></span>
                          <Link to="/" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }} target="_self"
                          >
                            <div>Home</div>
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <span className="menu-expand"></span>
                          <Link to="" target="_self" onClick={toggleMobileHeader}>
                            {/* <i>
                              <Icon icon="tabler:category-plus" color="#65B741" width="14" height="14" />
                            </i> */}
                            All Categories
                          </Link>
                          <ul className="dropdown">
                            {data.map((item) => (
                              <li key={item.category_id} >
                                <div className="d-flex justify-content-between">
                                  <Link
                                    to={`/category/${item.category_slug}`}
                                    onClick={toggleMobileHeader}
                                    className="d-flex justify-content-start gap-3 my-auto"
                                    style={{ verticalAlign: 'middle' }}
                                  >
                                    <img
                                      src={categoryurl + item.logo}
                                      width={30}
                                      height={30}
                                      alt={item.category_name}
                                    />
                                    <div className="p-2">{item.category_name}</div>
                                  </Link>

                                  <div className="p-2">
                                    {!openCategory ?
                                      <svg xmlns="http://www.w3.org/2000/svg" onClick={(e) => {
                                        e.preventDefault(); // Prevent the default anchor click behavior
                                        handleCategoryClick(item.category_id);
                                      }} width="1em" height="1em" viewBox="0 0 48 48"><defs><mask id="ipSDownC0"><g fill="none" strokeLinejoin="round" strokeWidth={4}><path fill="#fff" stroke="#fff" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"></path><path stroke="#000" strokeLinecap="round" d="m33 21l-9 9l-9-9"></path></g></mask></defs><path fill="#3bb77e" d="M0 0h48v48H0z" mask="url(#ipSDownC0)"></path></svg>
                                      :
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                        onClick={(e) => {
                                          e.preventDefault(); // Prevent the default anchor click behavior
                                          handleCategoryClick(item.category_id);
                                        }} width="1em" height="1em" viewBox="0 0 48 48"><defs><mask id="ipSUpC0"><g fill="none" strokeWidth={4}><path stroke="#fff" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z" clipRule="evenodd"></path><path fill="#fff" stroke="#fff" strokeLinejoin="round" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"></path><path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="m33 27l-9-9l-9 9"></path></g></mask></defs><path fill="#3bb77e" d="M0 0h48v48H0z" mask="url(#ipSUpC0)"></path></svg>
                                    }</div></div>
                                {openCategory === item.category_id && (
                                  <ul className="sub-menu">
                                    {item.subcategory.map((sub) => (
                                      <li key={sub.sub_category_id}>
                                        <Link
                                          to={`/subcategory/${sub.sub_category_slug}`}
                                          onClick={toggleMobileHeader}
                                        >
                                          {sub.sub_category_name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className=" menu-item-has-children ">
                          <span className="menu-expand"></span>
                          <Link to="/about-us" target="_self" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}
                          >
                            <div>About Us</div>
                          </Link>
                        </li>
                        <li className=" menu-item-has-children ">
                          <span className="menu-expand"></span>
                          <Link to="/blog" target="_self" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}
                          >
                            <div>Blog</div>
                          </Link>
                        </li>
                        <li className=" menu-item-has-children">
                          <span className="menu-expand"></span>
                          <Link to="/contact-us" target="_self" onClick={() => { props?.SearchFetch(); toggleMobileHeader(); }}
                          >
                            <div>Contact Us</div>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="mobile-social-icon mb-50">
                    <p className="mb-15 font-heading h6 me-2">Follow Us</p>
                    <Link to="https://www.facebook.com/share/XM1oRDDkvagcQKW9/?mibextid=qi2Omg" title="Facebook">
                      <img
                        src="/storage/general/facebook.png"
                        loading="lazy"
                        alt="Facebook"
                      />
                    </Link>

                    <Link to="https://www.instagram.com/keralaspecial?igsh=YTdxY3ByYnk2ZGpn" title="Instagram">
                      <img
                        src="/storage/general/instagram.png"
                        loading="lazy"
                        alt="Instagram"
                      />
                    </Link>
                    <Link to="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g&s=09" title="X">
                      <img
                        src="/storage/general/x.png"
                        loading="lazy"
                        alt="X"
                      />
                    </Link>
                    <Link to="https://youtube.com/@keralaspecial5256?si=XHIcNHk41dBE2Ast" title="Youtube">
                      <img
                        src="/storage/general/youtube.png"
                        loading="lazy"
                        alt="Youtube"
                      />
                    </Link>
                  </div>
                  <div className="site-copyright">
                    Copyright : Kitsor Pvt Ltd (Keralaspecial.in ). All rights reserved.
                    Powered by GoTech Solutions.                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

    </>
  );
}

export default HeaderMain;
