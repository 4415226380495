import React, { useEffect, useState } from "react";
import { backend, mediaurl, producturl } from "../../../../path";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../../../components/whatsapp connect";
import { Rating } from "@mui/material";
import { Helmet } from "react-helmet";
import LoaderMain from "../../../layout/header/loader_sub";
import UncontrolledExampleSpecial from "./carousel special";

function SpecialOccation(props) {

  // useEffect(()=>{
  //   let userdata = JSON.parse(localStorage.getItem('userdata'));
  //   fetch(backend + "tokentest", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization:`Bearer ${userdata.access_token}`,
  //       "Content-Type": "application/json",
  //     }
  //   })
  //     .then((response) => {
  //       response.json().then((data) => {
  //       console.log(data)

  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error during login:", error);
  //     });
  // },[])
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [subEmail, setSubEmail] = useState('');

  const [authenticated, setAuthenticated] = useState(() => {
    const userdata = localStorage.getItem("userdata");
    return userdata ? JSON.parse(userdata) : null;
  });

  const fetchData = () => {

    setLoading(true);

    fetch(backend + "giftproducts", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    fetchData();
  }, []);

  const handleAddCart = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };

  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange)
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubscribe = () => {

    fetch(backend + "addsubscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: subEmail }),
    })
      .then((response) => {
        setSubEmail('')
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 409) {
            console.error("Email Already Exists");
            toast.error("Email Already Exists", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            console.error("Registration failed");
            toast.error("Registration failed", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          toast.success("Subscribed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        toast.error("Registration failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      {loading &&
        <LoaderMain />
      }


      <Helmet><title>Best online products in kerala</title>
        <meta name="description" content="Discover Kerala's finest products online! Handicrafts, spices, Ayurveda, handlooms & more. Fast & reliable shipping." />
        <meta name="keywords" content="online shopping,online shopping sites,online store" />
      </Helmet>
      <main className="main" id="main-section" 
      // style={{backgroundImage:'url(/storage/general/7506708.jpg)', padding:'10px',objectFit:'cover'}}
      >
        <div className="ck-content">
          <h1 className="d-none">Handicrafts, Spices , Ayurveda , Handlooms</h1>
          <div>
            <section className="home-slider position-relative">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="home-slide-cover mt-30">
                      <div className="hero-slider-1 style-5 dot-style-1 dot-style-1-position-2">
                        {/* <UncontrolledExampleSpecial /> */}
                        <img src="storage/general/CELEBRATE (2).png" style={{width:'100%'}}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div>
            <section className="banners mt-30 d-none d-lg-block">
              <div className="container">
              </div>
            </section>
          </div>
          <div>
          </div>
          <div>
            <section className="product-tabs section-padding position-relative">
              <div className="container">
              <h4 className="section-title style-1 mb-30 animated animated" style={{color:'#000', fontWeight:'700', fontFamily:'"Kanit", sans-serif',justifyContent:'space-around'}}>
              CELEBRATE YOUR ONAM WITH KERALASPECIAL GIFT COMBOS
              </h4>
                <div className="row product-grid-4">
                  {!loading && (
                    data?.gift?.map((item, i) => (
                      <div
                        key={i}
                        className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-3"
                      >
                        <div
                          className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                          data-wow-delay="0.1s"
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                            border:'none',
                            backgroundColor:'transparent'
                          }}
                        >
                          <div className="product-img-action-wrap" style={{backgroundColor:'transparent'}}>
                            <div className="product-img product-img-zoom">
                            <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}>
                                <img
                                  src={producturl + item.thumbnail}
                                  loading="lazy"
                                  alt={item.product_name}
                                />
                              </Link>
                            </div>
                            <div className="product-action-1">
                              <Link
                                aria-label="Quick View"
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                className="action-btn hover-up js-quick-view-button"
                              >
                                <i className="fi-rs-eye"></i>
                              </Link>
                              <Link
                                aria-label="Add To Wishlist"
                                onClick={() => handleAddToWishList(item.product_id)}
                                className="action-btn hover-up js-add-to-wishlist-button"
                              >
                                <i className="fi-rs-heart"></i>
                              </Link>
                              {/* <a
                            aria-label="Add To Compare"
                            onClick={() => handleProductRoute(item.product_id)}
                            className="action-btn hover-up js-add-to-compare-button"
                          >
                            <i className="fi-rs-shuffle"></i>
                          </Link> */}
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg"></div>
                          </div>
                          <div className="product-content-wrap">
                            <div className="product-category">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                style={{color:'#fff'}}
                              >
                                {/* {item?.category_name} */}
                                #ONAM_KS_SPECIALS
                              </Link>
                            </div>
                            <h2 className="text-truncate">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                title="Nestle Original Coffee-Mate Coffee Creamer"
                                style={{fontStyle:'oblique', fontWeight:'600', fontSize:'20px'}}
                              >
                                {item?.product_name}
                              </Link>
                            </h2>
                            <div className="product-rate-cover">
                              <div className="d-inline-block">
                                <div className="d-inline-block" style={{color:'#fff'}}>
                                  {/* <Rating name="read-only" value={item?.average_stars} readOnly size="small" /> */}
                                  {/* Grab it with custom onam message for your oved ones */}
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  {/* ( {item?.comments?.length ?? "0"} reviews) */}
                                </span>
                              </div>
                            </div>
                            <div className="text-truncate">
                              <span className="font-small text-muted">
                                Sold By{" "}
                                <Link
                                  to={`/product/${item.product_slug}`}
                                  onClick={() =>
                                    handleProductRoute(item.product_slug)
                                  }
                                >
                                  {item?.vendor_name ?? "KeralaSpecial"}
                                </Link>
                              </span>
                            </div>
                            <div className="product-card-bottom">
                              <div className="product-price" >
                                <span style={{fontSize:'25px', color:'#5b0808'}}>₹ {item.mrp}</span>
                              </div>
                              <div className="add-cart">
                                <Link
                                  aria-label="Add To Cart"
                                  className="action-btn add-to-cart-button add"
                                  data-id="11"
                                  onClick={() =>
                                    handleAddCart(item.product_id)
                                  }
                                >
                                  <i className="fi-rs-shopping-cart mr-5"></i>
                                  <span className="d-inline-block">Add</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )))}
                </div>
              </div>
            </section>
            <section className="product-tabs section-padding position-relative">
              <div className="container">
              <h4 className="section-title style-1 mb-30 animated animated" style={{color:'#000', fontWeight:'700', fontFamily:'"Kanit", sans-serif',justifyContent:'space-around'}}>
                      AUTHENTICATED BALARAMAPURAM KAITHARIES
                    </h4>
                <div className="row product-grid-4">
                  {!loading && (
                    data?.balaramapuram?.map((item, i) => (
                      <div
                        key={i}
                        className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-3"
                      >
                        <div
                          className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                          data-wow-delay="0.1s"
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                            border:'none',
                            backgroundColor:'transparent'
                          }}
                        >
                          <div className="product-img-action-wrap" style={{backgroundColor:'transparent'}}>
                            <div className="product-img product-img-zoom">
                            <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}>
                                <img
                                  src={producturl + item.thumbnail}
                                  loading="lazy"
                                  alt={item.product_name}
                                />
                              </Link>
                            </div>
                            <div className="product-action-1">
                              <Link
                                aria-label="Quick View"
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                className="action-btn hover-up js-quick-view-button"
                              >
                                <i className="fi-rs-eye"></i>
                              </Link>
                              <Link
                                aria-label="Add To Wishlist"
                                onClick={() => handleAddToWishList(item.product_id)}
                                className="action-btn hover-up js-add-to-wishlist-button"
                              >
                                <i className="fi-rs-heart"></i>
                              </Link>
                              {/* <a
                            aria-label="Add To Compare"
                            onClick={() => handleProductRoute(item.product_id)}
                            className="action-btn hover-up js-add-to-compare-button"
                          >
                            <i className="fi-rs-shuffle"></i>
                          </Link> */}
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg"></div>
                          </div>
                          <div className="product-content-wrap">
                            <div className="product-category">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                style={{color:'#fff'}}
                              >
                                {/* {item?.category_name} */}
                                #ONAM_KS_SPECIALS
                              </Link>
                            </div>
                            <h2 className="text-truncate">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                title="Nestle Original Coffee-Mate Coffee Creamer"
                                style={{fontStyle:'oblique', fontWeight:'600', fontSize:'20px'}}
                              >
                                {item?.product_name}
                              </Link>
                            </h2>
                            <div className="product-rate-cover">
                              <div className="d-inline-block">
                                <div className="d-inline-block" style={{color:'#fff'}}>
                                  {/* <Rating name="read-only" value={item?.average_stars} readOnly size="small" /> */}
                                  {/* Grab it with custom onam message for your oved ones */}
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  {/* ( {item?.comments?.length ?? "0"} reviews) */}
                                </span>
                              </div>
                            </div>
                            <div className="text-truncate">
                              <span className="font-small text-muted">
                                Sold By{" "}
                                <Link
                                  to={`/product/${item.product_slug}`}
                                  onClick={() =>
                                    handleProductRoute(item.product_slug)
                                  }
                                >
                                  {item?.vendor_name ?? "KeralaSpecial"}
                                </Link>
                              </span>
                            </div>
                            <div className="product-card-bottom">
                              <div className="product-price" >
                                <span style={{fontSize:'25px', color:'#5b0808'}}>₹ {item.mrp}</span>
                              </div>
                              <div className="add-cart">
                                <Link
                                  aria-label="Add To Cart"
                                  className="action-btn add-to-cart-button add"
                                  data-id="11"
                                  onClick={() =>
                                    handleAddCart(item.product_id)
                                  }
                                >
                                  <i className="fi-rs-shopping-cart mr-5"></i>
                                  <span className="d-inline-block">Add</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )))}
                </div>
              </div>
            </section>
            <section className="product-tabs section-padding position-relative">
              <div className="container">
              <h4 className="section-title style-1 mb-30 animated animated" style={{color:'#000', fontWeight:'700', fontFamily:'"Kanit", sans-serif',justifyContent:'space-around'}}>
                      ONAM SPECIAL SNACKS OF KERALA
                    </h4>
                <div className="row product-grid-4">
                  {!loading && (
                    data?.snaks?.map((item, i) => (
                      <div
                        key={i}
                        className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-3"
                      >
                        <div
                          className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                          data-wow-delay="0.1s"
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                            border:'none',
                            backgroundColor:'transparent'
                          }}
                        >
                          <div className="product-img-action-wrap" style={{backgroundColor:'transparent'}}>
                            <div className="product-img product-img-zoom">
                            <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}>
                                <img
                                  src={producturl + item.thumbnail}
                                  loading="lazy"
                                  alt={item.product_name}
                                />
                              </Link>
                            </div>
                            <div className="product-action-1">
                              <Link
                                aria-label="Quick View"
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                className="action-btn hover-up js-quick-view-button"
                              >
                                <i className="fi-rs-eye"></i>
                              </Link>
                              <Link
                                aria-label="Add To Wishlist"
                                onClick={() => handleAddToWishList(item.product_id)}
                                className="action-btn hover-up js-add-to-wishlist-button"
                              >
                                <i className="fi-rs-heart"></i>
                              </Link>
                              {/* <a
                            aria-label="Add To Compare"
                            onClick={() => handleProductRoute(item.product_id)}
                            className="action-btn hover-up js-add-to-compare-button"
                          >
                            <i className="fi-rs-shuffle"></i>
                          </Link> */}
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg"></div>
                          </div>
                          <div className="product-content-wrap">
                            <div className="product-category">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                style={{color:'#fff'}}
                              >
                                {/* {item?.category_name} */}
                                #ONAM_KS_SPECIALS
                              </Link>
                            </div>
                            <h2 className="text-truncate">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                title="Nestle Original Coffee-Mate Coffee Creamer"
                                style={{fontStyle:'oblique', fontWeight:'600', fontSize:'20px'}}
                              >
                                {item?.product_name}
                              </Link>
                            </h2>
                            <div className="product-rate-cover">
                              <div className="d-inline-block">
                                <div className="d-inline-block" style={{color:'#fff'}}>
                                  {/* <Rating name="read-only" value={item?.average_stars} readOnly size="small" /> */}
                                  {/* Grab it with custom onam message for your oved ones */}
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  {/* ( {item?.comments?.length ?? "0"} reviews) */}
                                </span>
                              </div>
                            </div>
                            <div className="text-truncate">
                              <span className="font-small text-muted">
                                Sold By{" "}
                                <Link
                                  to={`/product/${item.product_slug}`}
                                  onClick={() =>
                                    handleProductRoute(item.product_slug)
                                  }
                                >
                                  {item?.vendor_name ?? "KeralaSpecial"}
                                </Link>
                              </span>
                            </div>
                            <div className="product-card-bottom">
                              <div className="product-price" >
                                <span style={{fontSize:'25px', color:'#5b0808'}}>₹ {item.mrp}</span>
                              </div>
                              <div className="add-cart">
                                <Link
                                  aria-label="Add To Cart"
                                  className="action-btn add-to-cart-button add"
                                  data-id="11"
                                  onClick={() =>
                                    handleAddCart(item.product_id)
                                  }
                                >
                                  <i className="fi-rs-shopping-cart mr-5"></i>
                                  <span className="d-inline-block">Add</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )))}
                </div>
              </div>
            </section>
            <section className="product-tabs section-padding position-relative">
              <div className="container">
              <h4 className="section-title style-1 mb-30 animated animated" style={{color:'#000', fontWeight:'700', fontFamily:'"Kanit", sans-serif',justifyContent:'space-around'}}>
                      HANDPICKED HANDICRAFTS FOR YOU
                    </h4>
                <div className="row product-grid-4">
                  {!loading && (
                    data?.handicrafts?.map((item, i) => (
                      <div
                        key={i}
                        className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 col-sm-3"
                      >
                        <div
                          className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                          data-wow-delay="0.1s"
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                            border:'none',
                            backgroundColor:'transparent'
                          }}
                        >
                          <div className="product-img-action-wrap" style={{backgroundColor:'transparent'}}>
                            <div className="product-img product-img-zoom">
                            <Link to={`/product/${item.product_slug}`} onClick={() => handleProductRoute(item.product_slug)}>
                                <img
                                  src={producturl + item.thumbnail}
                                  loading="lazy"
                                  alt={item.product_name}
                                />
                              </Link>
                            </div>
                            <div className="product-action-1">
                              <Link
                                aria-label="Quick View"
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                className="action-btn hover-up js-quick-view-button"
                              >
                                <i className="fi-rs-eye"></i>
                              </Link>
                              <Link
                                aria-label="Add To Wishlist"
                                onClick={() => handleAddToWishList(item.product_id)}
                                className="action-btn hover-up js-add-to-wishlist-button"
                              >
                                <i className="fi-rs-heart"></i>
                              </Link>
                              {/* <a
                            aria-label="Add To Compare"
                            onClick={() => handleProductRoute(item.product_id)}
                            className="action-btn hover-up js-add-to-compare-button"
                          >
                            <i className="fi-rs-shuffle"></i>
                          </Link> */}
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg"></div>
                          </div>
                          <div className="product-content-wrap">
                            <div className="product-category">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                style={{color:'#fff'}}
                              >
                                {/* {item?.category_name} */}
                                #ONAM_KS_SPECIALS
                              </Link>
                            </div>
                            <h2 className="text-truncate">
                              <Link
                                to={`/product/${item.product_slug}`}
                                onClick={() => handleProductRoute(item.product_slug)}
                                title="Nestle Original Coffee-Mate Coffee Creamer"
                                style={{fontStyle:'oblique', fontWeight:'600', fontSize:'20px'}}
                              >
                                {item?.product_name}
                              </Link>
                            </h2>
                            <div className="product-rate-cover">
                              <div className="d-inline-block">
                                <div className="d-inline-block" style={{color:'#fff'}}>
                                  {/* <Rating name="read-only" value={item?.average_stars} readOnly size="small" /> */}
                                  {/* Grab it with custom onam message for your oved ones */}
                                </div>
                                <span className="font-small ml-5 text-muted" style={{ verticalAlign: 'text-bottom' }}>
                                  {/* ( {item?.comments?.length ?? "0"} reviews) */}
                                </span>
                              </div>
                            </div>
                            <div className="text-truncate">
                              <span className="font-small text-muted">
                                Sold By{" "}
                                <Link
                                  to={`/product/${item.product_slug}`}
                                  onClick={() =>
                                    handleProductRoute(item.product_slug)
                                  }
                                >
                                  {item?.vendor_name ?? "KeralaSpecial"}
                                </Link>
                              </span>
                            </div>
                            <div className="product-card-bottom">
                              <div className="product-price" >
                                <span style={{fontSize:'25px', color:'#5b0808'}}>₹ {item.mrp}</span>
                              </div>
                              <div className="add-cart">
                                <Link
                                  aria-label="Add To Cart"
                                  className="action-btn add-to-cart-button add"
                                  data-id="11"
                                  onClick={() =>
                                    handleAddCart(item.product_id)
                                  }
                                >
                                  <i className="fi-rs-shopping-cart mr-5"></i>
                                  <span className="d-inline-block">Add</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )))}
                </div>
              </div>
            </section>
          </div>
          <div>

            <ScrollToTop />

          </div>
        </div>
      </main>
      <div
        className="modal fade custom-modal"
        id="quick-view-modal"
        tabindex="-1"
        aria-labelledby="quick-view-modal-label"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body">
              <div className="half-circle-spinner loading-spinner">
                <div className="circle circle-1"></div>
                <div className="circle circle-2"></div>
              </div>
              <div className="quick-view-content"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SpecialOccation;
