import React, { useEffect, useState } from "react";
import { backend, blogurl, producturl } from "../../../../path";
import { useParams } from "react-router-dom";
import LoaderMain from "../../../layout/header/loader_sub";

function BlogUserDetail() {

  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const { id } = useParams();

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + `getblog/${id}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  return (

    <div className="">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-item d-inline-block" title="Home"> Home </a>
            <span></span>
            <a href="/blog" className="breadcrumb-item d-inline-block" title="Home"> Blog </a>
            <span></span>
            <div className="breadcrumb-item d-inline-block active"> {data?.title} </div>
          </div>
        </div>
      </div>
      {loading &&
        <LoaderMain />
      }
      
      <div className="container">
        <section className="banners py-5">
          <div className="">
          <div className="col-lg-12 col-md-12">
          <a className="btn btn-lg mb-2 w-100"
                style={{ fontSize: '18px' }}
              >
                {data?.title}
              </a>
          </div>
            <div className="row justify-content-start">
              <div className="col-lg-12 col-md-12">
              <div class="ecommerce-gallery" data-mdb-ecommerce-gallery-init data-mdb-zoom-effect="true" data-mdb-auto-height="true">
  <div class="row py-3 shadow-5">
    <div class="col-12 mb-1">
      <div class="lightbox w-100 d-flex justify-content-center" data-mdb-lightbox-init>
        <img
          src={blogurl + data?.thumbnail}
          alt={data?.title}
          class="ecommerce-gallery-main-img active w-50"
          style={{borderRadius:'10px', maxHeight:'300px', backdropFilter:'inherit', backgroundPosition:'cover', alignItems:'center'}}
        />
      </div>
    </div>
    <div className="row my-3">
              <div className="col-lg-12 col-md-12">
                <p
                  dangerouslySetInnerHTML={{
                    __html: decodeHTML(data?.content),
                  }}
                />
              </div>
            </div>
    <div class="col-3 mt-1">
      <img
          src={blogurl + data?.image_1}
          data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/14a.webp"
        alt={data?.title}
        class="active w-100"
      />
    </div>
    <div class="col-3 mt-1">
      <img
          src={blogurl + data?.image_2}
          data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/12a.webp"
        alt={data?.title}
        class="w-100"
      />
    </div>
    <div class="col-3 mt-1">
      <img
          src={blogurl + data?.image_3}
          data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/13a.webp"
        alt={data?.title}
        class="w-100"
      />
    </div>
    <div class="col-3 mt-1">
      <img
          src={blogurl + data?.image_4}
          data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/15a.webp"
        alt={data?.title}
        class="w-100"
      />
    </div>
  </div>
</div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  );
}

export default BlogUserDetail;
